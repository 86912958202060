import { request } from "../lib/requests";

// Temp map values to translation
const mapping = { // Values from Airtable
	relationship: [
		{ value: 'mom', label: 'Mère' },
		{ value: 'dad', label: 'Père' },
		{ value: 'wife', label: 'Épouse' },
		{ value: 'husband', label: 'Époux' },
		{ value: 'grandfather', label: 'Grand-père' },
		{ value: 'grandmother', label: 'Grand-mère' },
		{ value: 'sister', label: 'Soeur' },
		{ value: 'brother', label: 'Frère' },
		{ value: 'daughter', label: 'Fille' },
		{ value: 'son', label: 'Fils' },
		{ value: 'aunt', label: 'Tante' },
		{ value: 'uncle', label: 'Oncle' },
		{ value: 'stepmother', label: 'Belle-mère' },
		{ value: 'stepdad', label: 'Beau-père' },
		{ value: 'cousin_f', label: 'Cousine' },
		{ value: 'cousin_m', label: 'Cousin' },
		{ value: 'relative', label: 'Proche' },
	],
	civil_status: [ // Values from the App
		{ value: "married", label: "married" },
		{ value: "widowed", label: "widow" },
		{ value: "divorced", label: "divorced" },
		{ value: "single", label: "single" },
		{ value: "legal_cohabitant", label: "legal_cohabitant" },
		{ value: "unknown", label: '' },
	],
	has_marriage_contract: [
		{ value: "yes", label: "Oui" },
		{ value: "no", label: "Non" },
		{ value: "unknown", label: 'Je ne sais pas' },
	],
	death_date: (value) => {
		const valueArray = value.split('-');
		return valueArray.length === 3 ? `${valueArray[2]}/${valueArray[1]}/${valueArray[0]}` : '';
	},
	death_cause: [
		{ value: "natural", label: "Naturelle" },
		{ value: "accidental", label: "Accidentelle" },
		{ value: "unknown", label: 'Je ne sais pas' },
	],
	address_region: [ // Not in Airtable
		{ value: "brussels", label: "Bruxelles" },
		{ value: "wallonia", label: "Wallonie" },
		{ value: "flanders", label: "Flandre" },
		{ value: "changed_recently", label: "Changement de domicile récent" },
	],
	has_testament: [
		{ value: "yes", label: "Oui" },
		{ value: "no", label: "Non" },
		{ value: "unknown", label: 'Je ne sais pas' },
	],
	children_number: (value) => Number(value) || 0,
	has_heir_minor_or_incapable: [
		{ value: "yes", label: "Oui" },
		{ value: "no", label: "Non" },
		{ value: "unknown", label: '' },
	],
	heirs_minor_or_incapable_number: (value) => Number(value) || 0,
	has_heir_abroad: [
		{ value: "yes", label: "Oui" },
		{ value: "no", label: "Non" },
		{ value: "unknown", label: '' },
	],
	has_property: [
		{ value: "yes", label: "Oui" },
		{ value: "no", label: "Non" },
		{ value: "unknown", label: 'Je ne sais pas' },
	],
	has_selled_property: [
		{ value: "yes", label: "Oui" },
		{ value: "no", label: "Non" },
		{ value: "unknown", label: '' },
	],
	selled_properties_number: (value) => Number(value) || 0,
	has_succession: [
		{ value: "yes", label: "Oui" },
		{ value: "no", label: "Non" },
		{ value: "unknown", label: '' },
	],
	successions_number: (value) => Number(value) || 0,
	has_vehicle: [
		{ value: "yes", label: "Oui" },
		{ value: "no", label: "Non" },
		{ value: "unknown", label: '' },
	],
	vehicles_number: (value) => Number(value) || 0,
	has_asset_abroad: [
		{ value: "yes", label: "Oui" },
		{ value: "no", label: "Non" },
		{ value: "unknown", label: '' },
	],
	referral: [
		{ value: "google", label: "Google" },
		{ value: "facebook", label: "Facebook" },
		{ value: "undertaker", label: "Referral PF" },
		{ value: "press", label: "Press" },
		{ value: "legacio_testament", label: "Already client" },
		{ value: "relative", label: "Bouche à oreille" },
		{ value: "undertaker_website", label: "Referral PF" },
		{ value: "legacio_succession", label: "Already client" },
	],
};

export async function postSuccessionOnboarding(data) {
	const newData = {};
	Object.keys(data).forEach(key => {
		let value = data[key];
		const mappingValue = mapping[key];

		if (!mappingValue) {
			// Do nothing
		} else if (Array.isArray(mappingValue)) {
			value = mappingValue.find(i => i.value === value)?.label || '';
		} else if (typeof mappingValue === 'function') {
			value = mappingValue(value);
		}

		// Only add non-empty values to newData
		if (value !== '' && value !== null && value !== undefined) {
			newData[key] = value;
		}
	});

	const storedItem = localStorage.getItem('succession_onboarding');
	const token = storedItem ? (JSON.parse(storedItem)?.token || '') : '';

	try {
		const response = await request({
			...token ? {
				method: 'PUT',
				url: `${process.env.REACT_APP_BASE_APP_URL}/api/v1/succession_onboarding`,
				extraHeaders: [
					['Authorization', `Bearer ${token}`],
					['Content-Type', 'application/json'],
				],
			} : {
				method: 'POST',
				url: 'https://hooks.zapier.com/hooks/catch/8288466/32ruyrl/',
			},
			data: newData,
		});

		return true;
	} catch (error) {
		console.error(error);

		return false;
	}
}
